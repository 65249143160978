import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {getMobileToken} from 'src/app/utils/utils.static';
import {AbstractWebservice} from "./abstract.webservice";
import {UrlBuilder} from "../../utils/helpers/url-builder.helper";

@Injectable({
  providedIn: 'root'
})
export class FilesWebservice extends AbstractWebservice {
  entity = `files`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  postUploadFile(idProgram: number, formData: FormData) {
    return this.post(`${this.root}/file/${idProgram}`, formData);
  }

  getFilesInfo(idProgram: number) {
    return this.get(`${this.root}/getFilesInfo/${idProgram}`);
  }

  downloadFile(fileName: string, idProgram: number) {
    return this.get(`${this.root}/download-file/${idProgram}/${fileName}`, {
      responseType: 'blob'
    });
  }

  deleteFile(fileName: string, idProgram: number) {
    const url = new UrlBuilder(this.root)
      .addPathParam('delete')
      .addQueryParam('fileName', fileName)
      .addQueryParam('idProgram', idProgram)
      .build();
    return this.delete(url);
  }

  deleteUserFile(idUser: number, fileName: string, idProgram: number) {
    const url = new UrlBuilder(this.root)
      .addPathParam('delete')
      .addPathParam(idUser)
      .addQueryParam('fileName', fileName)
      .addQueryParam('idProgram', idProgram)
      .build();

    return this.delete(url);
  }

  renameFile(idProgram: number, oldFileName: string, newFileName: string) {
    const url = new UrlBuilder(this.root)
      .addPathParams('rename-file', idProgram)
      .addQueryParam('oldFileName', oldFileName)
      .build();
    return this.post(url, newFileName);
  }

  getAnnexesFiles(idYoung) {
    return this.get(`${this.root}/annexes/${idYoung}`);
  }

  saveAnnexesFiles(idYoung, files) {
    return this.post(`${this.root}/annexes/${idYoung}`, files);
  }

  deleteAnnexe(idYoung, fileName, pDocument) {
    return this.delete(
      `${this.root}/annexes/${idYoung}?fileName=${fileName}&pDocument=${pDocument}`
    );
  }

  downloadInterview(idInterview: number, idYoung: number, filename: string[]) {
    return this.post(`${this.root}/entretien/${idInterview}/${idYoung}`, filename);
  }

  upload(analyzedFile: FormData) {
    return this.post(`${this.root}/upload`, analyzedFile);
  }

  downloadFileSigned(idEvent: number, idOccurrence: number) {
    return this.get(`${this.root}/download-signed-file/${idEvent}/${idOccurrence}`, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  downloadVisit(idVisit: number) {
    return this.get(`${this.root}/download-visit-file/${idVisit}`, {responseType: 'blob'});
  }
}
